export default {
    data() {
        return {
            payment_directions: [],
        }
    },

    methods: {
        initAccountPaymentTypes() {
            this.fetchAccountPaymentTypes()
                .then((payment_directions) => {
                    this.payment_directions = payment_directions;
                });
        },

        fetchAccountPaymentTypes(params = {}) {
            return new Promise((resolve, reject) => {
                this.$http.get('admin/payment_direction', params)
                    .then(({body}) => {
                        resolve(body.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
    }
}
